import React, { useState, useEffect  } from 'react';
import '../index.css';
import { Link, useLocation  } from 'react-router-dom';

function SuicideForestPage() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    
    const [currentSlide, setCurrentSlide] = useState(0);
    const images = [
      '/img/catalog/suicude-forest/suicude-forest-1.jpg',
      '/img/catalog/suicude-forest/suicude-forest-2.jpg',
      '/img/catalog/suicude-forest/suicude-forest-3.jpg'
    ];
  
    const nextSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
    };
  
    const prevSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
    };

    const [selectedSize, setSelectedSize] = useState('normal');
    const [a, setA] = useState(1);
  
    const handleSizeChange = (size) => {
      setSelectedSize(size);
      if (size === 'normal') {
        setA(1);
      } else if (size === 'big') {
        setA(2);
      }
    };

    function btnBuy() {
        if (a === 1) {
            document.cookie = 'suicide-forest-normal=1';
        } else if (a === 2) { 
            document.cookie = 'suicide-forest-big=1';
        } else {
            console.log('error')
        }
    }

    return (
        <div className='body'>

            <div className='_pc' style={{ width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <div style={{ position: 'relative', width: '700px', height: '700px', overflow: 'hidden', margin: '20px' }}  >
                            <img src={images[currentSlide]} alt={`Slide ${currentSlide + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            <button onClick={prevSlide} style={{ position: 'absolute', width: '40px', height: '40px', fontSize: '16px', color: '#fff', backgroundColor: '#0000006a', fontWeight: 100, border: 'none', top: '50%', left: '10px', transform: 'translateY(-50%)', zIndex: 1, cursor: 'pointer', }} >←</button>
                            <button onClick={nextSlide} style={{ position: 'absolute', width: '40px', height: '40px', fontSize: '16px', color: '#fff', backgroundColor: '#0000006a', fontWeight: 100, border: 'none', top: '50%', right: '10px', transform: 'translateY(-50%)', zIndex: 1, cursor: 'pointer', }}>→</button>
                        </div>
                    </div>

                    <div style={{ width: '100%', textAlign: 'left', marginLeft: '10px', animation: 'fadeInAnimationText ease 4s' }}>
                        <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '48px' }}>T-SHIRT "SUICIDE FOREST"</h1>
                        <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '32px', marginTop: '-30px' }}>2 800 RUB</h1>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div id='normal-size' className={selectedSize === 'normal' ? '_active' : '_none_active'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '150px', height: '50px', backgroundColor: '#000', border: 'solid 1px #262626', fontWeight: 100, fontSize: '24px'}} onClick={() => handleSizeChange('normal')}>NORMAL</div>
                            <div id='big-size' className={selectedSize === 'big' ? '_active' : '_none_active'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '150px', height: '50px', backgroundColor: '#000', border: 'solid 1px #262626', fontWeight: 100, fontSize: '24px', marginLeft: '15px'}} onClick={() => handleSizeChange('big')}>BIG</div>
                        </div>
                        <Link to='/cart' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '200px', height: '60px', bkgroundColor: '#000', border: 'solid 1px #262626', marginTop: '20px', color: '#fff', fontWeight: 100, fontSize: '36px', textDecoration: 'none', }} onClick={btnBuy}>BUY BOW</Link>
                        <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '32px', }}>FULL EMBROIDERY</h1>
                        <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '32px', marginTop: '-15px' }}>NORMAL SIZE - 165-180</h1>
                        <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '32px', marginTop: '-15px' }}>BIG SIZE - 180-195</h1>
                        <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '32px', marginTop: '-15px' }}>92 COTTTON / 8 LYCRA</h1>
                    </div>
                </div>
            </div>
            
            <div className='_mobile' style={{ width: '100%', height: '100vh', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginTop: '95%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <div style={{ position: 'relative', width: '90%', height: '100%', overflow: 'hidden', margin: '20px' }}  >
                            <img src={images[currentSlide]} alt={`Slide ${currentSlide + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            <button onClick={prevSlide} style={{ position: 'absolute', width: '40px', height: '40px', fontSize: '16px', color: '#fff', backgroundColor: '#0000006a', fontWeight: 100, border: 'none', top: '50%', left: '10px', transform: 'translateY(-50%)', zIndex: 1, cursor: 'pointer', }} >←</button>
                            <button onClick={nextSlide} style={{ position: 'absolute', width: '40px', height: '40px', fontSize: '16px', color: '#fff', backgroundColor: '#0000006a', fontWeight: 100, border: 'none', top: '50%', right: '10px', transform: 'translateY(-50%)', zIndex: 1, cursor: 'pointer', }}>→</button>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', animation: 'fadeInAnimationText ease 4s' }}>
                    <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '32px' }}>T-SHIRT "SUICIDE FOREST"</h1>
                    <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '28px', marginTop: '-20px' }}>2 800 RUB</h1>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div id='normal-size' className={selectedSize === 'normal' ? '_active' : '_none_active'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '150px', height: '50px', backgroundColor: '#000', border: 'solid 1px #262626', fontWeight: 100, fontSize: '24px'}} onClick={() => handleSizeChange('normal')}>NORMAL</div>
                        <div id='big-size' className={selectedSize === 'big' ? '_active' : '_none_active'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '150px', height: '50px', backgroundColor: '#000', border: 'solid 1px #262626', fontWeight: 100, fontSize: '24px', marginLeft: '15px'}} onClick={() => handleSizeChange('big')}>BIG</div>
                    </div>
                    <Link to='/cart' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '200px', height: '60px', backgroundColor: '#000', border: 'solid 1px #262626', marginTop: '25px', color: '#fff', fontWeight: 100, fontSize: '36px', textDecoration: 'none', }} onClick={btnBuy}>buy now</Link>
                    <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '26px', marginTop: '50px' }}>FULL EMBROIDERY</h1>
                    <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '26px', marginTop: '-15px' }}>NORMAL SIZE - 165-180</h1>
                    <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '26px', marginTop: '-15px' }}>BIG SIZE - 180-195</h1>
                    <h1 style={{ color: '#fff', fontWeight: 100, fontSize: '26px', marginTop: '-15px', }}>92 COTTTON / 8 LYCRA</h1>
                </div>
                <div style={{ width: '100%', height: '200px', display: 'flex', flexDirection: 'column', bottom: 0, marginTop: '30px', paddingBottom: '30px' }}> 
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <img style={{ width: '60px',height: '50px', paddingRight: '10px' }} src='/img/logo/logo.png' alt='img' />
                        <h1 style={{ fontSize: '32px', fontWeight: '100', color: '#fff' }}>abstraction</h1>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <a href='https://vk.com/abstractionstore' style={{ fontSize: '28px', fontWeight: '100', color: '#fff',padding: '0px 10px 0px 10px', marginTop: '7.5px', cursor: 'pointer', textDecoration: 'none' }}>VKONTAKTE</a>
                        <a href='https://t.me/abstractionstore' style={{ fontSize: '28px', fontWeight: '100', color: '#fff',padding: '0px 10px 0px 10px', marginTop: '7.5px', cursor: 'pointer', textDecoration: 'none' }}>TELEGRAM</a>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Link to='/catalog' style={{ fontSize: '28px', fontWeight: '100', color: '#fff', padding: '0px 10px 0px 10px', marginTop: '5px', cursor: 'pointer', textDecoration: 'none' }}>CATALOG</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {SuicideForestPage};
